<template>
  <div class="text-xs pt-4 pb-8">
    I consent for {{ tenantName }}, its franchisees, affiliates and/or agents to call, text, or email me for any
    purpose at any number provided including via automatic telephone dialing system and/or artificial or
    prerecorded voice, and such consent is not required to purchase goods or services as I may always call
    directly here: {{ primaryPhoneDisplay }}.
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useFormatPhoneNumber } from '../../website/composables/useFormatPhoneNumber';

const { formatPhoneNumber } = useFormatPhoneNumber();

const props = defineProps({
  primaryPhone: {
    type: String,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
});

const primaryPhoneDisplay = computed(() => formatPhoneNumber(props.primaryPhone, 'dots'));

</script>
