import { provisionApp, registerGlobalComponents } from '../Vue/Global';

import LeadGeneration from './LeadGeneration';

const requireContextComponents = require.context('../components', true, /\.vue$/i);
const requireContextCmComponents = require.context('./components', true, /\.vue$/i);

const app = $app.register({
    LeadGeneration,
}).createApp();

provisionApp(app);

registerGlobalComponents(app, [
    requireContextComponents,
    requireContextCmComponents,
]);

app.mount('#app');
