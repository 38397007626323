<template>
  <div
    v-for="(question, index) in modelValue"
    :key="index"
  >
    <FormInput
      v-if="question.questionType === 'text'"
      :errors="errors[question.questionText]"
    >
      <input
        v-model="question.answer"
        type="text"
        :placeholder="getPlaceholder(question)"
      >
    </FormInput>
    <FormInput
      v-if="question.questionType === 'phone'"
      :errors="errors[question.questionText]"
    >
      <input
        v-model="question.answer"
        v-mask="'(###) ###-####'"
        type="tel"
        :placeholder="getPlaceholder(question)"
      >
    </FormInput>
    <div
      v-if="question.questionType === 'address'"
      class="grid grid-cols-2 gap-4 sm:grid-cols-5"
    >
      <FormInput
        :errors="errors[question.questionText]"
        class="col-span-2 sm:col-span-4"
      >
        <input
          v-model="question.answer.address1"
          type="text"
          :placeholder="`Address${question.required ? '*' : ''}`"
        >
      </FormInput>
      <FormInput class="col-span-2 sm:col-span-1">
        <input
          v-model="question.answer.address2"
          type="text"
          placeholder="Apt"
        >
      </FormInput>
      <FormInput class="col-span-2 sm:col-span-3">
        <input
          v-model="question.answer.city"
          type="text"
          :placeholder="`City${question.required ? '*' : ''}`"
        >
      </FormInput>
      <FormInput class="col-span-1 sm:col-span-1">
        <input
          v-model="question.answer.state"
          type="text"
          :placeholder="`State${question.required ? '*' : ''}`"
        >
      </FormInput>
      <FormInput class="col-span-1 sm:col-span-1">
        <input
          v-model="question.answer.zip"
          type="text"
          :placeholder="`Zip${question.required ? '*' : ''}`"
        >
      </FormInput>
    </div>
    <div v-if="question.questionType === 'yesno'">
      <span v-text="getPlaceholder(question)" />
      <FormToggle
        class="mt-2"
        label="Yes"
      >
        <input
          v-model="question.answer"
          type="radio"
          value="yes"
        >
      </FormToggle>
      <FormToggle
        class="mt-2"
        label="No"
      >
        <input
          v-model="question.answer"
          type="radio"
          value="no"
        >
      </FormToggle>
      <div
        v-for="(error, k) in errors[question.questionText]"
        :key="k"
        class="help-block mt-1 text-danger"
      >
        <VIcon i="error" />
        <span
          class="text-xs"
          v-text="error"
        />
      </div>
    </div>
    <div v-if="question.questionType === 'multiple'">
      <span v-text="getPlaceholder(question)" />
      <template
        v-for="choice in question.multipleChoices"
        :key="choice.id"
      >
        <FormToggle
          class="mt-2"
          :label="choice.choiceText"
        >
          <input
            v-model="question.answer"
            type="radio"
            :value="choice.choiceText"
          >
        </FormToggle>
      </template>
      <div
        v-for="(error, k) in errors[question.questionText]"
        :key="k"
        class="help-block mt-1 text-danger"
      >
        <VIcon i="error" />
        <span
          class="text-xs"
          v-text="error"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import VIcon from '../../components/VIcon.vue';
import FormToggle from '../../components/FormToggle.vue';
import FormInput from '../../components/FormInput.vue';

defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

function getPlaceholder(question) {
  return `${question.questionText}${question.required ? '*' : ''}`;
}
</script>

<style
  scoped
  lang="scss"
>
.form-input {
  @apply mb-0;
}

.form-input input {
  @apply border-none;
}
</style>
