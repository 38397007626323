<template>
  <div class="flex justify-between items-center gap-y-4 gap-x-12 flex-col max-w-3xl pb-4 xl:flex-row">
    <div class="flex flex-row flex-col sm:flex-row gap-x-6 gap-y-2 items-center sm:items-start">
      <div class="flex shrink-0 justify-center items-center rounded-full bg-neutral overflow-hidden h-28 w-28">
        <img
          v-if="headshot"
          :src="headshot"
          class="h-full w-full object-cover"
          alt="headshot"
        >
        <template v-else>
          <p
            v-if="initials"
            class="text-white m-0 text-5xl"
            v-text="initials"
          />
          <VIcon
            v-else
            class="text-white text-5xl !flex items-center justify-center"
            i="user"
          />
        </template>
      </div>
      <div class="flex flex-col items-center sm:items-start">
        <h4
          class="m-0 font-bold"
          v-text="name"
        />
        <span
          v-if="config.showTitle && title"
          v-text="title"
        />
        <span
          v-if="config.showDesignations && designations"
          v-text="designations"
        />
        <span
          v-if="config.showHeadline && headline"
          v-text="headline"
        />
        <span v-if="config.showPhone && phoneDisplay">
          Direct: {{ phoneDisplay }}
        </span>
        <span class="flex gap-x-2 pt-2">
          <a
            v-if="config.showEmail && email"
            :href="`mailto:${email}`"
            target="_blank"
          >
            <VIcon
              i="mail-outline-rev-circle"
              class="text-3xl text-neutral"
            />
          </a>
          <a
            v-if="config.showSite && siteLink"
            :href="siteLink"
            target="_blank"
          >
            <VIcon
              i="link-outline-rev-circle"
              class="text-3xl text-neutral"
            />
          </a>
          <template v-if="config.showSocialLinks">
            <a
              v-for="(socialLink, index) in socialLinks"
              :key="index"
              :href="socialLink.url"
              target="_blank"
              class="cursor-pointer !p-0"
            >
              <VIcon
                :i="`${socialLink.slug}-outline-rev-circle`"
                class="text-3xl text-neutral"
              />
            </a>
          </template>
        </span>
      </div>
    </div>
    <div>
      <img
        v-if="config.showLogo && logo"
        :src="logo"
        alt="logo"
        class="max-w-[13rem] max-h-32"
      >
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useFormatPhoneNumber } from '../../website/composables/useFormatPhoneNumber';
import { useFormatName } from '../../website/composables/useFormatName';

const { formatPhoneNumber } = useFormatPhoneNumber();
const { getInitials } = useFormatName();

const props = defineProps({
  signature: {
    type: Object,
    required: true,
  },
  config: {
    type: Object,
    required: true,
  },
  phone: {
    type: String,
    required: true,
  },
});

const headshot = computed(() => props.config.isTeam ? props.signature.headshot?.team : props.signature.headshot?.agent);
const name = computed(() => props.config.isTeam ? props.signature.name?.team : props.signature.name?.agent);
const phoneDisplay = computed(() => formatPhoneNumber(props.phone, 'dots'));
const email = computed(() => props.config.isTeam ? props.signature.email?.team : props.signature.email?.agent);
const siteLink = computed(() => {
  if (props.config.isTeam && props.signature.profile.team) {
    return /^https?:\/\//i.test(props.signature.profile?.team) ? props.signature.profile?.team : `https://${props.signature.profile?.team}`;
  }

  if (!props.config.isTeam && props.signature.profile.agent) {
    return /^https?:\/\//i.test(props.signature.profile?.agent) ? props.signature.profile?.agent : `https://${props.signature.profile?.agent}`;
  }

  return null;
});
const logo = computed(() => props.config.isTeam ? props.signature.logo?.team?.uri : props.signature.logo?.agent?.uri);
const title = computed(() => props.config.isTeam ? null : props.signature.title?.agent);
const designations = computed(() => props.config.isTeam ? null : props.signature.designations?.agent);
const headline = computed(() => props.config.isTeam ? null : props.signature.headline?.agent);
const socialLinks = computed(() => props.config.isTeam ? null : props.signature.socialLinks?.agent ?? []);
const initials = computed(() => name.value ? getInitials(name.value) : null);

</script>
