<template>
  <div
    class="flex items-center justify-center text-center py-8 md:py-11 px-6 text-2xl md:text-4xl"
    v-text="form.name"
  />
  <div class="grid grid-cols-1 lg:grid-cols-2 border-t-2 border-neutral-200">
    <div
      :style="`background-image: url(${form.image})`"
      class="h-56 lg:h-[calc(100vh-130px)] bg-cover bg-no-repeat bg-center"
    />
    <div class="max-h-auto lg:max-h-[calc(100vh-130px)] overflow-y-auto lg:overflow-y-scroll">
      <div
        v-if="showSuccess"
        class="flex flex-wrap text-3xl justify-center content-center text-center bg-neutral-100 h-screen lg:h-[calc(100vh-135px)]"
      >
        Thank you for your submission!
      </div>
      <div v-else>
        <div class="bg-neutral-100">
          <div class="flex items-center justify-center py-8">
            <img
              :src="logo"
              class="max-w-lg max-h-40"
              alt="logo"
            >
          </div>
          <div class="max-w-lg mx-auto px-4">
            <p
              class="text-center px-5 pt-5 pb-6"
              v-text="form.description"
            />
            <form
              class="space-y-4"
              @submit.prevent
            >
              <QuestionInputs
                v-model="orderedQuestions"
                :errors="errors"
              />
              <SubmitDisclaimer />
              <button
                type="button"
                class="mt-4 btn btn-primary btn-lg w-full"
                @click="submit"
              >
                Submit
              </button>
              <FormDisclaimer
                :primary-phone="form.primaryPhone"
                :tenant-name="tenantName"
              />
            </form>
          </div>
        </div>
        <div
          class="flex justify-center lg:w-fit lg:max-w-2xl lg:min-w-[32rem] px-4 mt-6 lg:mx-auto"
          :class="{'border-b border-[#CCCCCC]': !form.coAgent}"
        >
          <AgentSignature
            :config="form.signatureConfig"
            :signature="form.signature"
            :phone="form.primaryPhone"
          />
        </div>
        <div
          v-if="form.coAgent"
          class="flex justify-center border-b border-[#CCCCCC] lg:w-fit lg:max-w-2xl lg:min-w-[32rem] px-4 mt-6 lg:mx-auto"
        >
          <AgentSignature
            :config="form.coAgentSignatureConfig"
            :signature="form.coAgentSignature"
            :phone="form.secondaryPhone"
          />
        </div>
        <div class="text-sm text-center p-4">
          <FormFooter
            :office="form.agent.office_location"
            :direct-phone="form.agent.directOffice"
            :state-licenses="form.agent.stateLicensesString"
          />
        </div>
      </div>
    </div>
    <div
      v-loader:lead-generation-loader
      class="overlay !bg-neutral-100"
    >
      <VIcon
        i="spinner"
        :spin="true"
        class="text-6xl"
      />
    </div>
  </div>
</template>

<script setup>
import FormFooter from './FormFooter.vue';
import QuestionInputs from './QuestionInputs.vue';
import SubmitDisclaimer from './SubmitDisclaimer.vue';
import FormDisclaimer from './FormDisclaimer.vue';
import _ from 'lodash';
import { computed, ref } from 'vue';
import AgentSignature from './AgentSignature.vue';
import Api from '../../services/Api';
import VIcon from '../../components/VIcon.vue';
import Alert from '../../services/Alert';
import { submitWithRecaptcha } from '../../recaptcha';

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  logo: {
    type: String,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
});

const errors = ref({});
const showSuccess = ref(false);

const emit = defineEmits(['update-form']);

const orderedQuestions = computed(() => _.orderBy(props.form.questions, 'sortOrder').filter((question) => question.active));

function submit() {
  if (validateAnswers()) {
    // Update form with questions
    const tempForm = props.form;
    tempForm.questions = orderedQuestions.value;
    emit('update-form', tempForm);

    submitWithRecaptcha((recaptchaResponse) => {
      Api.loader('lead-generation-loader')
        .post(`lead-form/${props.form.leadFormHash}`, {
          recaptchaResponse,
          questions: props.form.questions,
        })
        .then(() => {
          showSuccess.value = true;
        }).catch(error => {
          Alert.error(error);
        });
    }, 'submit_custom_lead_form');
  }
}

function validateAnswers() {
  errors.value = {};

  orderedQuestions.value.forEach(question => {
    if (!question.required) {
      return;
    }

    if (!question.answer) {
      errors.value[question.questionText] = ['Please answer the required question.'];
    }

    if (question.questionType === 'address') {
      if (!question.answer.address1 || !question.answer.city || !question.answer.state || !question.answer.zip) {
        errors.value[question.questionText] = ['Please answer the required question.'];
      }
    }
  });
  return Object.keys(errors.value).length <= 0;
}
</script>
