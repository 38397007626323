<template>
  <span v-text="officePhoneDisplay" />
  <br class="block md:hidden">
  <span
    v-if="officePhoneDisplay"
    class="hidden md:inline"
  >
    |
  </span>
  <span v-text="officeAddressDisplay" />
  <div
    v-if="stateLicenses"
    class="pt-1"
  >
    License No. {{ stateLicenses }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useFormatPhoneNumber } from '../../website/composables/useFormatPhoneNumber';

const { formatPhoneNumber } = useFormatPhoneNumber();

const props = defineProps({
  office: {
    type: Object,
    required: true,
  },
  directPhone: {
    type: String,
    default: '',
  },
  stateLicenses: {
    type: String,
    default: '',
  },
});

const officeAddressDisplay = computed(() => {
  const addressParts = [
    ...(props.office.address1 ? [props.office.address1] : []),
    ...(props.office.address2 ? [props.office.address2] : []),
    ...[cityStateZip.value],
  ];

  return addressParts.join(' | ');
});
const cityStateZip = computed(() => `${props.office.city}, ${props.office.state} ${props.office.zip}`);
const officePhoneDisplay = computed(() => props.directPhone ? `Office: ${formatPhoneNumber(props.directPhone, 'dots')}` : '');

</script>
