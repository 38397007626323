import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        beforeMount() {
            this.resetForm();
        },

        data() {
            return appData;
        },

        methods: {
            resetForm() {
                this.app.questions.forEach((question) => {
                    question.answer = question.questionType === 'address'
                        ? { address1: '', address2: '', city: '', state: '', zip: '' }
                        : '';
                });
            },
            updateForm(form) {
                this.app = form;
            },
        },
    });
}
