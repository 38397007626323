import { VueMaskFilter } from 'v-mask';

export function useFormatPhoneNumber() {
    function standardizeFormat(format) {
        switch (format) {
            case 'parens':
            case 'parentheses':
                return '(###) ###-####';
            case 'dashes':
                return '###-###-####';
            case 'dots':
                return '###.###.####';
            case 'spaces':
                return '### ### ####';
            case 'digits':
                return '##########';
            default:
                return format;
        }
    }

    function stripExtension(number) {
        if (number === null || number === undefined) {
            return '';
        }

        const extStart = number.toLowerCase().indexOf('x');
        if (extStart >= 0) {
            return number.substring(0, extStart);
        }
        return number;
    }

    function formatPhoneNumber(number, format) {
        if (number === null || number === undefined) {
            return '';
        }

        let digitsOnly = String(number);
        digitsOnly = stripExtension(digitsOnly);
        digitsOnly = digitsOnly.replace(/\D/g, '');

        if (digitsOnly.length === 11 && digitsOnly.charAt(0) === '1') {
            // If country code 1 is included, strip it out.
            digitsOnly = digitsOnly.substring(1);
        } else if (digitsOnly.length !== 10) {
            return String(number);
        }

        format = standardizeFormat(format);

        return format ? VueMaskFilter(digitsOnly, format) : digitsOnly;
    }

    function liveFormatPhoneNumber(number, format = '(###) ###-####') {
        let digitsOnly = String(number).replace(/\D/g, '');

        if (digitsOnly.charAt(0) === '1') {
            digitsOnly = digitsOnly.substring(1);
        }

        let formatted = VueMaskFilter(digitsOnly, standardizeFormat(format));

        while (formatted && ['(', ')', '-', ' ', '.'].includes(formatted.charAt(formatted.length - 1))) {
            formatted = formatted.substring(0, formatted.length - 1);
        }

        return formatted;
    }

    return {
        formatPhoneNumber,
        liveFormatPhoneNumber,
        stripExtension,
    };
}
