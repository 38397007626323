<template>
  <VModal name="privacy-policy-modal">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        @click="$modal.hide('privacy-policy-modal')"
      >
        <VIcon i="close" />
      </button>
      <h3 class="modal-title">
        Privacy Policy
      </h3>
    </div>
    <div class="modal-body space-y-4">
      <p>
        At World Properties, LLC affiliates (collectively “we”, “our” or “us”) want you to be familiar you with how we
        collect, use, and disclose information about you. This Privacy Policy outlines the types of information we
        gather from your use of our website at {{ websiteDomain }} and other websites we operate from which
        you are accessing this Privacy Policy and the At World Properties, LLC mobile application and other At World
        Properties, LLC-branded applications made available by us through mobile devices (“Apps”) (collectively the
        “Services”), how we use it, with whom we might share it, how we keep it secure, and the choices you have about
        the information you choose to share with us.
      </p>
      <h2>
        What information do we collect?
      </h2>
      <p>
        You may visit our website(s) or use our mobile apps anonymously. If Location Services are enabled on your
        device, we may use your location to help you search for nearby listings. If you do not want this assistance,
        you can disable Location Services on your device.
      </p>
      <p>
        When registering on our site or filling out a form, as appropriate, you may be asked to enter your name,
        e-mail address or phone number. You will be contacted in response to any information you request, but you may
        prevent your contact information from being automatically saved for future contacts by
        <a
          href="mailto:privacy@atproperties.com"
          target="_blank"
        >
          contacting us here
        </a>.
      </p>
      <h2>
        What do we use your information for?
      </h2>
      <p>
        Any of the information we collect from you may be used in one of the following ways:
      </p>
      <ul>
        <li>
          To personalize your experience (your information helps us to better respond to your individual needs).
        </li>
        <li>
          To improve our website (we continually strive to improve our website offerings based on the information and
          feedback we receive from you).
        </li>
        <li>
          To improve customer service (your information helps us to more effectively respond to your customer service
          requests and support needs).
        </li>
        <li>
          To process transactions. Your information, whether public or private, will not be sold, exchanged,
          transferred, or given to any other company for any reason whatsoever, without your consent, other than for
          the express purpose of delivering the product or service requested.
        </li>
        <li>
          To send periodic emails, either at your request (e.g., notifications of new listings that match your search
          criteria) or of a marketing nature, either of which you may opt-out from receiving at any time.
        </li>
      </ul>
      <p>
        Any email address you provide may be used to send you information, respond to inquiries, and/or other requests
        or questions.
      </p>
      <h2>
        How do we protect your information?
      </h2>
      <p>
        We implement a variety of security measures to maintain the safety of your personal information when you
        enter, submit, or access your personal information.
      </p>
      <h2>
        Do we use cookies?
      </h2>
      <p>
        Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive
        through your web browser (if you allow) that enables the site's or service provider's systems to recognize
        your browser, and capture and remember certain information.
      </p>
      <p>
        We use cookies to understand and save your preferences for future visits, as well as provide a website
        experience customized to your needs, including geolocation if relevant to your searches and if allowed by you
        on your device.
      </p>
      <p>
        You may disable your browser’s ability to save cookies at any time, usually from within the browser’s privacy
        or security preferences. You may also remove cookies already saved to your hard drive.
      </p>
      <h2>
        Do we disclose any information to outside parties?
      </h2>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This
        does not include trusted third parties who assist us in conducting our business or servicing you, so long as
        those parties agree to keep this information confidential and not use it for any other purpose beyond these.
        We may also release your information when we believe release is appropriate to comply with the law, enforce
        our site policies, or protect ours or others' rights, property, or safety.
      </p>
      <h2>
        Retention Period
      </h2>
      <p>
        We will retain your data for as long as needed or permitted in light of the purpose(s) for which it was
        obtained and consistent with applicable law. The criteria used to determine our retention periods include: (i)
        the length of time we have an ongoing relationship with you and provide the Service to you; (ii) whether there
        is a legal obligation to which we are subject; or (iii) whether retention is legally advisable (for example,
        in regard to applicable statutes of limitations, litigation or regulatory investigations).
      </p>
      <h2>
        GDPR
      </h2>
      <p>
        If you are a covered individual under the General Data Protection Regulation (“GDPR”) you have legal rights
        in relation to the data that we hold about you. These rights include:
      </p>
      <ul>
        <li>
          Obtaining information regarding the processing of your data and access to the data that we hold about you.
          Please note that there may be circumstances where we are entitled to refuse requests for access to copies of
          data.
        </li>
        <li>
          Requesting that we correct your data if it is inaccurate or incomplete.
        </li>
        <li>
          Requesting that we erase your data in certain circumstances. Please note that there may be circumstances
          where you ask us to erase your data, but we are legally entitled to retain it.
        </li>
        <li>
          Objecting to, and requesting that we restrict, our processing of your data in certain circumstances. Again,
          there may be circumstances where you object to, or ask us to restrict, our processing of your data but we
          are legally entitled to refuse that request.
        </li>
        <li>
          Withdrawing your consent, although in certain circumstances it may be lawful for us to continue processing
          without your consent if we have another legitimate reason (other than consent) for doing so.
        </li>
      </ul>
      <p>
        We may request that you prove your identity by providing us with a copy of a valid means of identification to
        comply with our security obligations and to prevent unauthorized disclosure of data. We reserve the right to
        charge you a reasonable administrative fee for any manifestly unfounded or excessive requests concerning your
        access to your data and for any additional copies of the data you request from us.
      </p>
      <p>
        Individuals covered by the GDPR should <a
          href="mailto:privacy@atproperties.com"
          target="_blank"
        >contact us here</a> to
        request further information.
      </p>
      <h2>
        Transferring your Data Outside the EEA (if GDPR Applies)
      </h2>
      <p>
        The data that we collect from you is transferred to, and will be stored in, the United States that is not
        subject to equivalent data Protection Law. For our purposes, the information we collect is processed and
        stored in the US.
      </p>
      <p>
        We may transfer your data outside the European Economic Area (“EEA”):
      </p>
      <ul>
        <li>
          In order to store it.
        </li>
        <li>
          In order to enable us to provide the Service.
        </li>
        <li>
          Where we are legally required to do so.
        </li>
        <li>
          In order to facilitate the operation of our group of businesses, where it is in our legitimate interests and
          we have concluded these are not overridden by your rights.
        </li>
      </ul>
      <p>
        Where your information is transferred outside the EEA, we will take all steps reasonably necessary to ensure
        that your data is subject to appropriate safeguards and that it is treated securely and in accordance with
        this Privacy Policy.
      </p>
      <p>
        You may <a
          href="mailto:privacy@atproperties.com"
          target="_blank"
        >contact us here</a> at any time if you would like further
        information.
      </p>
      <h2>
        Your Consumer Rights Under U.S. State-Specific Privacy Laws
      </h2>
      <p>
        Consumers covered by U.S. state-specific privacy laws have the right to request information regarding their
        personal information and certain additional details regarding our information practices. California consumers
        have the right to request deletion of their personal information (subject to certain exceptions). Covered
        consumers also have the right to opt out of sales of personal information, if applicable. We describe how
        covered consumers can exercise their rights under the relevant state privacy laws below. Please note that you
        may designate an authorized agent to exercise these rights on your behalf by providing a notarized power of
        attorney evidencing that you have empowered the authorized agent to exercise your rights on your behalf. We
        will not discriminate against you if you choose to exercise your rights under the applicable state laws.
      </p>
      <ul>
        <li>
          <strong>Sharing of information:</strong> Covered U.S. consumers are entitled to request certain information
          with respect to the types of personal information we share with third parties for direct marketing purposes
          or to opt out of such sharing. If you are a covered U.S. consumer and would like a copy of this information,
          or to opt out, please email us at privacy@atproperties.com.
        </li>
        <li>
          <strong>Right to Know:</strong> You may request access to the specific pieces of personal information we
          have collected about you in the last 12 months. You may also request additional details about our
          information practices, including the categories of personal information we have collected, the sources of
          collection, the purpose for collecting information, the categories of information we share, and the
          categories of third parties with whom we share information. You may make a request for such information by
          emailing privacy@atproperties.com. We will contact you after receiving your request to verify your identity.
        </li>
        <li>
          <strong>Deletion:</strong> You may request that we delete the personal information we have collected about
          you (subject to certain exceptions). Please note that we may retain certain information as required or
          permitted by applicable law. You may make this request by privacy@atproperties.com. We will contact you
          after receiving your request to verify your identity. If you request to delete your personal information,
          some of our products and services may no longer be available to you.
        </li>
        <li>
          <strong>"Do Not Sell:"</strong> Pursuant to certain U.S. state laws, covered consumers have the right to opt
          out of the "sale" of their personal information (as defined in the relevant laws. Covered consumers who wish
          to opt out of the "sale" of their personal information can email us at privacy@atproperties.com or initiate
          your request now by clicking here. We will contact you after receiving your request to verify your identity.
        </li>
      </ul>
      <h2>
        Categories of Personal Information Collected.
      </h2>
      <p>
        At World Properties, LLC and its affiliates collect the categories of personal information listed below.
      </p>
      <ul>
        <li>
          Identifiers, such as name, email address, and phone number (if phone number is provided by the user).
        </li>
        <li>
          Categories of personal information such as search and listing preferences the user may have submitted, and
          portfolio information the user may have created when creating an account on the website.
        </li>
        <li>
          Internet and other similar network activity limited to what is available in customer's Browser configuration
          and the website’s site navigation/pages visited.
        </li>
        <li>
          Inferences drawn from the above information to create consumer profiles reflecting certain preferences and
          behaviors.
        </li>
      </ul>
      <h2>
        Categories of Personal Information We Share.
      </h2>
      <p>
        At World Properties, LLC and its affiliates share the categories of personal information listed below.
      </p>
      <ul>
        <li>
          Identifiers, such as name, email address, and phone number (if phone number is provided by the user).
        </li>
        <li>
          Categories of personal information such as search and listing preferences the user may have submitted, and
          portfolio information the user may have created when creating an account on the website.
        </li>
        <li>
          Internet and other similar network activity limited to what is available in customer's Browser configuration
          and the website’s site navigation/pages visited.
        </li>
        <li>
          Inferences drawn from the above information to create consumer profiles reflecting certain preferences and
          behaviors.
        </li>
      </ul>
      <h2>
        Third-Party Links
      </h2>
      <p>
        Occasionally, at our discretion, we may include or offer third-party products or services on our website.
        These third-party sites have separate and independent privacy policies. We therefore have no responsibility or
        liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity
        of our site and welcome any feedback about these sites.
      </p>
      <h2>
        Questions or Revoking Consent
      </h2>
      <p>
        If you have questions regarding our Privacy Policy, or if you wish to update the personal information you have
        provided to us, you may do so by <a
          href="mailto:privacy@atproperties.com"
          target="_blank"
          class="underline"
        >contacting us here</a>.
      </p>
      <p>
        Should you wish to revoke your previously-given consent to this Privacy Policy
        <a href="#revoke-cookie-consent">click here</a>. If you have previously registered on our website and wish to
        un-register <a
          href="mailto:privacy@atproperties.com"
          target="_blank"
          class="underline"
        >contact us here</a>.
      </p>
      <h2>
        Changes to Our Privacy Policy
      </h2>
      <p>
        If we decide to change our privacy policy, we will post those changes on this page.
      </p>
      <h2>
        Updates and Effective Date
      </h2>
      <p>
        We reserve the right to make changes to our Privacy Policy from time to time for any reason. Such changes,
        modifications, additions or deletions shall be effective immediately upon posting, unless otherwise indicated.
        We have no duty or obligation to inform prior visitors that changes have been made, regardless of the scope
        and importance of the changes. We encourage you to periodically check back and review this Privacy Policy so
        that you always will know what information we collect, how we use it, and to whom we disclose it. Your
        continued use of the site after such changes are posted will be deemed to constitute your agreement to and
        acceptance of such changes.
      </p>
      <p>
        <button
          id="revoke-cookie-consent"
          type="button"
          @click="revokeCookieConsent"
        >
          Revoke Cookie Consent
        </button>
      </p>
    </div>
  </VModal>
</template>

<script setup>
import VModal from '../../components/VModal.vue';
import Alert from '../../services/Alert';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();
const COOKIE_ACCEPTANCE_COOKIE = 'cookie-consent-notice-acceptance';

defineProps({
  websiteDomain: {
    type: String,
    required: true,
  },
});

function revokeCookieConsent() {
  cookies.set(COOKIE_ACCEPTANCE_COOKIE, 0, '1y');
  Alert.toastSuccess("You've revoked your consent.");
}

</script>

<style
  scoped
  lang="scss"
>
.modal-body {
  p, ul, li {
    @apply text-sm;
  }

  h2 {
    @apply t-header text-2xl font-bold;
  }

  a, button {
    @apply underline hover:opacity-75;
  }

  ul {
    @apply list-disc pl-10;
  }
}
</style>
