export function useFormatName() {
    function getInitials(name) {
        const nameParts = name.trim().split(/\s+/g);
        if (nameParts.length > 1) {
            const first = nameParts[0].trim().charAt(0).toUpperCase();
            const last = nameParts[nameParts.length - 1].trim().charAt(0).toUpperCase();
            return first + last;
        } else if (nameParts.length === 1) {
            return nameParts[0].trim().charAt(0).toUpperCase();
        }
        return '';
    }

    return {
        getInitials,
    };
}
