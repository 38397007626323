<template>
  <div class="text-xs">
    By clicking the Submit button below, I agree to the
    <a
      role="button"
      class="underline hover:opacity-75"
      @click="$modal.show('privacy-policy-modal')"
    >
      Privacy Policy
    </a>
    and
    <a
      role="button"
      class="underline hover:opacity-75"
      @click="$modal.show('terms-of-use-modal')"
    >
      Terms of Use
    </a>
    and the Consent Policy below, which I have read.
  </div>
</template>
